import { TranslateService } from '@ngx-translate/core';
import { Guid } from '../util/Guid';
import { LxmAppModule } from './app-module';
import { UserAction } from './user-action';
import { PurchaseOrderStatus } from './purchase-order-status';
import { ContentUnitType } from './content-unit-type';
import { Unit, UnitShort } from './unit';

export const registry: { [index: string]: any } = {
  [(<any>Unit).__typeName]: Unit,
  [(<any>UnitShort).__typeName]: UnitShort,
  [(<any>UserAction).__typeName]: UserAction,
  [(<any>LxmAppModule).__typeName]: LxmAppModule,
  [(<any>PurchaseOrderStatus).__typeName]: PurchaseOrderStatus,
  [(<any>ContentUnitType).__typeName]: ContentUnitType,
};

function asArray<T extends { [index: number]: string }>(
  values: T,
): IEnumValue[] {
  return Object.keys(values)
    .filter((value) => isNaN(Number(value)) === false)
    .map((key) => asObject(values, Number(key)));
}

function asObject<T extends { [index: number]: string }>(
  values: T,
  key: number,
) {
  return { id: key, name: getKey(values, key), label: values[key] };
}

function asBoolArray<T extends { [index: string]: any }>(
  values: T,
): { id: string; name: string }[] {
  return Object.keys(values)
    .filter((value) => typeof values[value] === 'boolean')
    .map((value) => ({
      id: values[value],
      name: getKey(values, values[value]),
    }));
}

function asGuidArray<T extends { [index: string]: any }>(
  values: T,
): { id: string; name: string }[] {
  return Object.keys(values)
    .filter((value) => Guid.isValid(values[value]))
    .map((value) => ({
      id: values[value],
      name: getKey(values, values[value]),
      label: value,
    }));
}

function translate<T extends { [index: string]: any }>(
  values: T,
  key: any,
  __?: TranslateService,
) {
  if (key === null) {
    return '';
  }

  if (typeof values === 'string') {
    values = registry[values];
  }

  if (isNaN(key) && !Guid.isValid(key)) {
    key = values[key];
  }

  return __ ? __.instant(getKey(values, key)) : values[key];
}

function getKey<T extends { [index: string]: any }>(values: T, key: any) {
  if (
    values &&
    values['__altTranslationKeys'] &&
    values['__altTranslationKeys'][key]
  ) {
    return values['__altTranslationKeys'][key];
  }

  return `enums.${(<any>values).__typeName}.${values[key]}`;
}

function label<T extends { [index: string]: any }>(values: T, key: any) {
  return values[key];
}

export interface IEnumValue {
  id: number;
  name: string;
  label: string;
}

export {
  asArray,
  asBoolArray,
  asGuidArray,
  translate,
  getKey,
  asObject,
  label,
  UserAction,
  LxmAppModule,
  PurchaseOrderStatus,
  ContentUnitType,
  Unit,
  UnitShort,
};
